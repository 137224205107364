import React from "react";
import PenetrationTesting from "../../sections/Services/SingleService/PenetrationTesting";
import RedTeamAsService from "../../sections/Services/SingleService/RedTeamAsService";
import CyberSecurityConsultancy from "../../sections/Services/SingleService/CyberSecurityConsultancy";
import CyberSecurityToolsDevelopment from "../../sections/Services/SingleService/CyberSecurityToolsDevelopment";
import OsintAnalytics from "../../sections/Services/SingleService/OsintAnalytics";

const ServicePage = ({ params }) => {
  return (
    <>
      {params.id === "penetration-testing" && <PenetrationTesting />}
      {params.id === "red-team-as-a-service" && <RedTeamAsService />}
      {params.id === "cyber-security-consultancy" && (
        <CyberSecurityConsultancy />
      )}
      {params.id === "cyber-security-tools" && (
        <CyberSecurityToolsDevelopment />
      )}
      {params.id === "osint-analytics" && <OsintAnalytics />}
    </>
  );
};

export default ServicePage;
